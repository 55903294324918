
import Http from './Http'

export default {
    async getNotifications(page=1,limit=100, filters = '') {
        return await Http.get(`/notification?page=${page}&limit=${limit}`+filters)
    },
    async getNotificationTypes() {
        return await Http.get(`/notification/types`)
    },
    async create(data) {
        return await Http.post(`/notification/create`,data)
    },
    async deleteNotification(notification_group_id) {
        return await Http.delete(`notification/${notification_group_id}`)
    },
    async getNotificationByGroup(notification_group_id) {
        return await Http.get(`notification/${notification_group_id}`)
    },
}


