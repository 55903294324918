<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Notification from "@/services/Notification";

/**
 * Starter page
 */
export default {
    components: { Layout, PageHeader, countTo },
    page: {
        title: "Notification Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    async created() {
        this.getNotificationByGroup(this.$route.query.id)
    },
    methods: {
        getNotificationByGroup(id){
            Notification.getNotificationByGroup(id)
            .then(response => {
                //console.log(response.data)
                this.notifInfo = response.data.notification;
                this.stats = response.data.stats;
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },
    },
    data() {
        return {
            title: "Notification Details",
            items: [
                {
                    text: "Notifications",
                    href: "/notifications",
                },
                {
                    text: this.$route.query.id,
                    active: true,
                },
            ],
            notifInfo: {

            },
            stats: {
                processed: {
                    IOS: 0, 
                    Android: 0
                },
                success: {
                    IOS: 0, 
                    Android: 0
                },
                error: {
                    IOS: 0, 
                    Android: 0
                },
            }
        };
    },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-primary"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-primary">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.processed.IOS + stats.processed.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-primary" style="font-size: x-large;">Notification Processed</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-success"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-success">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.success.IOS + stats.success.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-success" style="font-size: x-large;">Notification Accepted</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-danger"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-danger">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.error.IOS + stats.error.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-danger" style="font-size: x-large;">Notification Rejected</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
<!-- end row-->

    <div class="row">
        <div class="col-md-6 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title center">Notification Data</h4>
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Message</th>
                                    <th>Recipient</th>
                                    <th>Type</th>
                                    <th>Language</th>
                                    <th>Set Up / Scheduled	</th>
                                    <th>Initiated</th>
                                    <th>Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{{notifInfo.notification_id}}</th>
                                    <td>{{notifInfo.title}}</td>
                                    <td>{{notifInfo.message}}</td>
                                    <td>{{notifInfo.recipient}}</td>
                                    <td>{{notifInfo.type}}</td>
                                    <td>{{notifInfo.language}}</td>
                                    <td>{{notifInfo.set_up}}</td>
                                    <td>{{notifInfo.initiated}}</td>
                                    <td>{{notifInfo.completed}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>
